import React from 'react'

const PageTemplate = () => {
    return (
        <div>
            Hello from a page template :)
        </div>
    )
}

export default PageTemplate
